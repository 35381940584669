<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row justify="start">
        <v-col cols="12" class="pb-1">
          <h2>All Users</h2>
          <p class="text-body-1 font-italic text--secondary mb-0">
            Total users who have submitted resumes: {{ userCount }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-system-bar color="accent" height="5"></v-system-bar>
          <div class="d-flex">
            <v-text-field
              class="pb-2"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-data-table
            :headers="usersHeader"
            :items="localUsers"
            dense
            :search="search"
            :items-per-page="25"
            @click:row="handleUser"
            class="selectable"
            :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapMutations } from "vuex";
import * as disp from "../../utility/display.js";

// @ is an alias to /src
export default {
  name: "AllUsers",
  data: () => ({
    showPast: false,
    search: "",
    localUsers: [],
    error: "",
  }),
  components: {},
  mounted() {
    this.buildUserList();
    this.loadUsers().then(this.buildUserList);
  },
  watch: {},
  computed: {
    ...mapFields("environment", ["activeReviews", "users"]),
    ...mapFields("adminUserReviews", ["loadUserResumeError"]),
    showError() {
      return !disp.IsNullorWhitespace(this.error);
    },
    userCount() {
      return this.localUsers?.length ?? 0;
    },
    usersHeader() {
      return [
        { text: "Name", sortable: true, value: "displayName" },
        { text: "Email", sortable: true, value: "userName" },
      ];
    },
  },
  methods: {
    ...mapActions("environment", ["loadUsers"]),
    ...mapActions("adminUserReviews", ["loadResumeReviews"]),
    ...mapMutations("adminUserReviews", ["setUserInformation"]),
    buildUserList() {
      let tempList = [];
      this.users.forEach((r) => {
        tempList.push(r);
      });

      this.localUsers = tempList;
    },
    getLength(resumes) {
      if (resumes?.length > 0) {
        return `(${resumes.length})`;
      }
      return "";
    },
    async handleUser(value) {
      this.setUserInformation({
        data: { userDisplay: value.displayName, userName: value.userName, userId: value.userId },
      });

      await this.loadResumeReviews(value.userId);
      if (!disp.IsNullorWhitespace(this.loadUserResumeError)) {
        this.error = `Unable to load resumes for user ${value.displayName}`;
      } else {
        this.nav(`/UserDetails/${value.userId}`);
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
};
</script>

<style>
.selectable tbody tr {
  cursor: pointer;
}
</style>